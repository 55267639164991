import logo from '../logo.svg';
import './Mobile.css';
import topbanner from "../assets/png/image-mobile.png"
import leftBanner from "../assets/icons/left-banner.svg"
import gridArrow from "../assets/icons/grid-arrow.svg"
import backTT from "../assets/icons/back-timetable.svg"
import backSMI from "../assets/icons/back-smi.svg"
import cardBack from "../assets/icons/card.svg"
import ic1 from "../assets/icons/ic1.svg"
import ic2 from "../assets/icons/ic2.svg"
import ic3 from "../assets/icons/ic3.svg"
import ic4 from "../assets/icons/ic4.svg"
import ic5 from "../assets/icons/ic5.svg"
import leg from "../assets/png/leg-mobile.png"
import grass from "../assets/png/grass.png"
import footer from "../assets/png/footer.png"
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Mobile() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.matchMedia("(min-width: 480px)").matches) {
      navigate('/mobile');
    } else {
      navigate('/');
    }
 }, [navigate]);

  return (
    <div className="mobile">
       <div className="header-mobile" style={{backgroundImage:`url("${topbanner}")`}}>
        <h1>ТЕХНО КУБОК</h1>
        <h3>спортивно-деловое мероприятие</h3>
        <div className="titles-top-banner">
              <div className="top-title" style={{width:"110px"}}>
                <p >спорт</p>
              </div>
              <div className="top-title" style={{backgroundColor:"#000000", width:"181px"}}>
                <p style={{color:"white"}}>стажировки</p>
              </div>
              <div className="top-title"  style={{backgroundColor:"#FFFFFF", width:"150px"}}>
                <p>нетворкинг</p>
              </div>
              <div className="top-title"  style={{backgroundColor:"rgba(0,0,0,0)", border:"3px solid #94CC68", height:"27px", width:"135px"}}>
                <p style={{color:"white"}}>технологии</p>
              </div>
          </div>
          <h2>МАЙ-НОЯБРЬ</h2>
          <p className='rosmol'>*при поддержке федерального агенства Росмолодежь</p>
      </div>

      <div className='mobile-grid'>
        <div className='grid-header'>
          <h2>Техно - Кубок</h2>
          <h3><spam style={{backgroundColor:"#94CC68"}}>уникальный </spam> формат мероприятия, объединяющий в себе спорт и деловую прорамму</h3>
        </div>
        <div className='squares'>
        <div className="square">
          <h1>1</h1>
          <h3>футбольный турнир за ценные призы от партнеров мероприятия (сертификаты на технику или спорт-товары)</h3>
        </div>

        
        <div className="square">
          <h1>2</h1>
          <h3>стажировки для студентов-соискателей в топовых IT-компаниях России</h3>
        </div>

        <div className="square">
          <h1>3</h1>
          <h3>увлекательные конкурсы спортивной, и не только, тематики за призы</h3>
        </div>

        <div className="square">
          <h1>4</h1>
          <h3>уникальный неформальный нетворкинг</h3>
        </div>
        
        <div className="square">
          <h1>5</h1>
          <h3>приглашенная звезда футбола</h3>
        </div>
        </div>
      </div>


      <div className='mobile-banner'>
      <div className="text-banner">
          <h1>отборочные матчи</h1>
          <h2>Матчи будут проводится среди студентов, которые учатся на IT-специальностях</h2>
        </div>
        <img src={leg}></img>
      </div>

      <div className='black-banner'>
        <div>
          <h3>все победители смогут принять участие в финале кубка</h3>
          <h3>путём жеребьевки команды будут распределены на группы</h3>
        </div>
        <div >
          <h3>проигравшие команды так же смогут принять участие в ярмарке вакансий</h3>
          <h3>в каждой группе будет по 4 команды (из группы могут выйти только 2 команды)</h3>
        </div>
      </div>

      <div className='timetable-mobile'>
      <h1>программа финала</h1>
          <div style={{marginLeft:"40px" , display:"flex", flexDirection:"column", gap:"8px"}}>
            <div className="line">
              <div className="time"><h3>9:00</h3></div>
              <h3>Подготовка спонсоров и ярмарки вакансий к финалу</h3>
            </div>

            <div className="line">
              <div className="time"><h3>10:00</h3></div>
              <h3>Регистрация команд и посетителей</h3>
            </div>

            <div className="line">
              <div className="time"><h3>11:00</h3></div>
              <h3>Торжественное открытие финалу</h3>
            </div>

            <div className="line">
              <div className="time"><h3>11:30</h3></div>
              <h3>Начало футбольных матчей</h3>
            </div>

            <div className="line">
              <div className="time" style={{height:"47px"}}>
                <h3 style={{marginBottom:"0px"}}>14:00</h3>
                <h3 style={{marginTop:"8px"}}>14:30</h3>
              </div>
              <h3>Кофе-брейк посетителей и обед для спортсменов</h3>
            </div>

            <div className="line">
              <div className="time"><h3>16:30</h3></div>
              <h3>Торжественное закрытие: подведение итогов и вручение призов</h3>
            </div>
          </div>
      </div>

      <div className='partners-mobile'>
      <div style={{width:"70%"}}>
          <h1>наши партнеры</h1>
          <h2>Предоставят призы победителям турнира и конкурсов:</h2>
          <h3>Для победителей конкурса подарки от магазина спорт-товаров</h3>
          <h3>Розыгрыш спортивной футболки знаменитости среди всех участников турнира от благотворительного фонда</h3>
          <h3><spam style={{backgroundColor:"#94CC68"}}>Сертификаты </spam> в магазин техники на каждого члена команды:</h3>
        </div>
        <div className="prizes">
          <div>
            <h1>2</h1>
            <div className="blackbox" style={{height:"68px"}}>
              <h3>20 000 рублей</h3>
              </div>
          </div>
          <div>
            <h1>1</h1>
            <div className="blackbox" style={{height:"98px"}}><h3>30 000 рублей</h3></div>
          </div>
          <div>
            <h1>3</h1>
            <div className="blackbox" style={{height:"54px"}}><h3>10 000 рублей</h3></div>
          </div>
        </div>
      </div>

      <div className='label-mobile'>
          <h1>площадка</h1>
          <h2>Площадка на метро Щукинская </h2>
          <h2>(15 минут от метро на транспорте)</h2>
      </div>

      <div className='grass-banner' style={{backgroundImage:`url("${grass}")`}}>
          <div className='black-banner'>
            <div>
              <h3>2 больших футбольных поля и выделенное место для стендов</h3>
              <h3>теплые раздевалки для спортсменов</h3>
            </div>
            <div >
              <h3>небольшой фудкорт на территории</h3>
              <h3>бесплатная парковка для личного автомобиля</h3>
            </div>
          </div>
      </div>

      <div className='label-mobile'>
          <h1 style={{marginBottom:"0px"}}>спонсорские</h1>
          <h1 style={{marginTop:"0px"}}>пакеты</h1>
      </div>
          <div className='sponsors-mobile'>
                <div className="card" style={{backgroundImage:`url("${cardBack}")`}}>
                    <h2>серебряный спонсор</h2>
                </div>
                <div className="card" style={{backgroundImage:`url("${cardBack}")`}}>
                    <h2>золотой спонсор</h2>
                </div>
                <div className="card" style={{backgroundImage:`url("${cardBack}")`}}>
                    <h2>генеральный спонсор</h2>
                </div>
          </div>

      <div className='label-mobile'>
          <h1 style={{marginBottom:"0px"}}>работодателям</h1>
      </div>

      <div className="topics-mobile">
            <div className="topic-pont">
              <img src={ic1}></img>
              <h3>стоимость стенда - 30 000 руб</h3>
            </div>

            <div className="topic-pont">
              <img src={ic2}></img>
              <h3>база студентов в поисках работы</h3>
            </div>

            <div className="topic-pont">
              <img src={ic3}></img>
              <h3>более 300 студентов профильных направлений</h3>
            </div>

            <div className="topic-pont">
              <img src={ic4}></img>
              <h3>студенты уже горячие и сами подходят за вакансиями</h3>
            </div>

            <div className="topic-pont">
              <img src={ic5}></img>
              <h3>постоянная активность в медийном поле, упоминание компании в рекламных постах</h3>
            </div>
        </div>

      <div className='smi'>
        <h1>предложение для сми</h1>
        <div style={{marginRight:"30px"}}>
            <div className="line">
              <h3 style={{color:"white", fontSize:"10px"}}>Взаимная <spam style={{color:"#94CC68"}}>реклама</spam>;</h3>
            </div>

            <div className="line">
              <h3 style={{color:"white", fontSize:"10px"}}><spam style={{color:"#94CC68"}}>Освещение </spam> профильного мероприятия;</h3>
            </div>

            <div className="line">
              <h3 style={{color:"white", fontSize:"10px"}}><spam style={{color:"#94CC68"}}>Размещение логотипов </spam>компаний на футбольных накидках и баннерах;</h3>
            </div>

            <div className="line">
              <h3 style={{color:"white", fontSize:"10px"}}>И <spam style={{color:"#94CC68"}}>многое </spam> другое.</h3>
            </div>

          </div>
      </div>

      <div className='mobile-grid'>
        <div className='grid-header'>
          <h2>План развития</h2>
          <h3>Развитие уникальной <spam style={{backgroundColor:"#94CC68"}}>концепции </spam>  внедрения спорта в отрасль ИТ</h3>
        </div>
        <div className='squares'>
        <div className="square">
          <h1>1</h1>
          <h3>Товарищеские матчи два раза в месяц</h3>
        </div>

        
        <div className="square">
          <h1>2</h1>
          <h3>Развитие собственного телеграмм-канала про спорт и IT</h3>
        </div>

        <div className="square">
          <h1>3</h1>
          <h3>Стажировки для студентов от компаний-партнеров;</h3>
        </div>

        <div className="square">
          <h1>4</h1>
          <h3>Лекции о здоровом образе жизни</h3>
        </div>
        
        <div className="square">
          <h1>5</h1>
          <h3>Нетворкинг-мероприятия</h3>
        </div>

        <div className="square">
          <h1>6</h1>
          <h3>Масштабирование проекта на всю страну</h3>
        </div>

        </div>
      </div>

    <div className='green-footer'>
      <div style={{marginLeft:"40px"}}>
          <h1>команда организаторов</h1>
          <h3 style={{fontWeight:"600"}}>Ответственный за работу с участниками - Белоногов Иван</h3>
          <h3 style={{fontWeight:"600"}}>Ответственный за работу со спонсорами - Вахрушин Ярослав</h3>
          <h3 style={{fontWeight:"600"}}>Ответственный за рекламу и PR - Крутилин Роман</h3>
          <div style={{height:"4px"}}></div>
          <h3 style={{fontWeight:"800"}}>Контакты</h3>
          <h3 style={{fontWeight:"600"}}>yvakhrushin@mail.ru</h3>
          </div>
      </div>

      <div style={{height:"171px", overflow:"hidden", width:"100%"}}>
        <img style={{height:"inherit", transform:"translateX(-110px)"}} src={footer}></img>

      </div>
    </div>
  );
}

export default Mobile;
